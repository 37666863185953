<template>
    <div class="page_dv2">
        <div class="center_dv">
            <img class="cha" @click="cha" src="../assets/close.png" alt=""><br />
            <div class="title2">添加微信咨询</div>
            <img class="v_img" src="../assets/vCode.png" alt="">
            <!-- <a class="save_img" href="https://xingtaike-oss-1318298083.cos.ap-beijing.myqcloud.com/appimg/vCode.png" download="download.png">保存图片</a> -->
             <div class="save_img" @click="saveImg">保存图片</div>
        </div>
    </div>
</template>

<script> 
export default {
    name: 'TopNav',


    data() {
        return {

        }
    },
    methods: {
        cha() { this.$emit('closeWecode') },
        async saveImg() {
            try {
                const response = await fetch('https://xingtaike-oss-1318298083.cos.ap-beijing.myqcloud.com/appimg/vCode.png');
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = '行者智能.png';

                document.body.appendChild(a);
                a.click();

                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            } catch (error) {
                console.error('下载图片失败:', error);
            }
        },
    }
}
</script>


<style scoped lang="scss">
.page_dv2 {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 999;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .center_dv {
        width: 360px;
        height: 373px;
        background-color: #ffd24a;
        border-radius: 15px;
        position: relative;
        text-align: center;

        .cha {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 25px;
            cursor: pointer;
        }

        .v_img {
            width: 200px;
            margin-top: 30px;
        }
    }

    .title2 {
        font-size: 24px;
        color: #0c0f1f;
        margin-top: 30px;
        font-weight: 600;
    }

    .save_img {
        text-align: center;
        color: #409eff;
        margin-top: 10px;
        font-weight: 800;
        cursor: pointer;
        display: block;
    }
}
</style>