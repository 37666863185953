import { createRouter, createWebHistory } from 'vue-router'
const router = createRouter({
  history: createWebHistory(), // 这里必须是history模式 其他模式会导致后面打包生成的静态文件内容都是一样的，不能起到预渲染的作用
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      component: () => import( '@/views/HomeView.vue')
    },
    {
      path: '/function',
      name: 'function',
      component: () => import( '@/views/Function.vue')
    },
    {
      path: '/question',
      name: 'question',
      component: () => import( '@/views/Question.vue')
    },
    {
      path: '/aboutus',
      name: 'aboutus',
      component: () => import( '@/views/AboutUs.vue')
    },
    {
      path: '/callus',
      name: 'callus',
      component: () => import( '@/views/CallUs.vue')
    },
     /*  {
          path: '/index',
          name: 'index',
          component: () => import('@/views/home/index.vue')
      },
      {
          path: '/test',
          name: 'test',
          component: () => import('@/views/test/index.vue')
      } */
  ]
})

export default router
/* const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import( '../views/HomeView.vue')
  },
  {
    path: '/function',
    name: 'function',
    component: () => import( '../views/Function.vue')
  },
  {
    path: '/question',
    name: 'question',
    component: () => import( '../views/Question.vue')
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: () => import( '../views/AboutUs.vue')
  },
  {
    path: '/callus',
    name: 'callus',
    component: () => import( '../views/CallUs.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router */
