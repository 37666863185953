<template>
  <router-view />
  <div class="kefu">
    <img @click="isShow = true" src="./assets/kefu.png" alt="">
  </div>
  <Contact v-show="isShow" @closeWecode="isShow = false"></Contact>
</template>
<script>
import Contact from '@/components/Contact.vue'
export default {
  name: 'TopNav',
  components: {
    Contact
  },
  data() {
    return {
      isShow: false,
    }
  },
  methods: {

  }
}
</script>
<style lang="scss">
@media (min-width: 600px) {
  #app {
    width: 100%;
    height: 100%;
    min-width: 1200px;

  }
}

@media (max-width: 600px) {
  #app {
    width: 100%;
    height: 100%;
  }
}


* {
  margin: 0;
  padding: 0;
  border: 0;
  scroll-behavior: smooth; //平滑滚动
}

.kefu {
  width: 52px;
  height: 52px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: #F25727;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);

  img {
    width: 35px;
    display: block;
  }
}
</style>
